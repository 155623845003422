<template>
  <div>
    <div class="analytics__cards">
      <div
          class="analytics__card"
          @click="selectView(0)"
          :class="isActiveCard(0)"
      >
        <img width="72" :src="require(`@/assets/img/deals/table.svg`)" alt=""/>
        <p>Таблица</p>
      </div>
      <div
          class="analytics__card"
          @click="selectView(1)"
          :class="isActiveCard(1)"
      >
        <img width="72" :src="require(`@/assets/img/deals/kanban.svg`)" alt=""/>
        <p>Воронка продаж</p>
      </div>
    </div>
    <es-table v-if="!dealsView" class="deals" :headers="headers" :items="deals" @update="getDealsPages" sortable
              :filter="filters"
              download="deals.export" :importData="importData" date-filter @downloadData="downloadData" @uploadData="uploadData" upload="deals.import" :importFormats="importFormats" :exportFormats="exportFormats">
      <div class="deals__actions" v-if="hasRight('deals.manage')">
        <h3 style="color: #68C9E7">Таблица</h3>
        <v-btn class="evi-button-green evi-button-green--fill mr-3" @click="createDealModal = true">
          Добавить сделку
        </v-btn>
      </div>
      <v-dialog
          v-model="createDealModal"
          max-width="612px"
      >
        <CreateDeal @updateDeals="getData" @close="createDealModal = false"/>
      </v-dialog>
      <template slot="sort-menu">
        <v-list dense class="settings-menu">
          <v-list-item-group>
            <v-list-item>
              <v-list-item-title>
                <v-autocomplete
                    class="evi-autocomplete mb-5"
                    color="#44D370"
                    v-model="filters['filter[deal_status_id]']"
                    :items="statuses"
                    item-text="name"
                    item-value="id"
                    hide-details
                    clearable
                    item-color="green"
                >
                  <template v-slot:label>
                    Фильтровать по статусу
                  </template>
                  <template v-slot:append>
                    <span class="evi-select__arrow">
                        <v-icon>mdi-chevron-down</v-icon>
                    </span>
                  </template>
                  <template v-slot:selection="{item}">
                    <div class="v-select__selection v-select__selection--comma"
                         :style="{color: `${item.color}`}">{{ item.name }}
                    </div>
                  </template>
                  <template v-slot:item="{item}">
                    <v-list-item-title :style="{color: `${item.color}`}">
                      {{ item.name }}
                    </v-list-item-title>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                    class="evi-autocomplete mb-5"
                    color="#44D370"
                    :items="clients"
                    v-model="filters['filter[client_id]']"
                    item-text="name"
                    item-value="id"
                    hide-details
                    :loading="clientsLoading"
                    @focus="loadClients"
                    :search-input.sync="clientSearch"
                    @input.native="searchClient(clientSearch)"
                    clearable
                    item-color="green"
                >
                  <template v-slot:label>
                    Фильтровать по клиенту
                  </template>
                  <template v-slot:append-item>
                    <div v-intersect="clientIntersect"/>
                  </template>
                  <template v-slot:item="{item}">
                    <v-avatar
                        color="#FFFFFF"
                        size="30"
                        class="user-page__avatar mr-3"
                    >
                      <img
                          :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                          alt=""
                      >
                    </v-avatar>
                    <div class="v-list-item__title">{{ item.name }}</div>
                  </template>
                  <template v-slot:append>
                                    <span class="evi-autocomplete__arrow">
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </span>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                    class="evi-autocomplete mb-5"
                    color="#44D370"
                    :items="users"
                    v-model="filters['filter[executor_id]']"
                    item-text="name"
                    item-value="id"
                    hide-details
                    :loading="userLoading"
                    @focus="loadUsers"
                    :search-input.sync="userSearch"
                    @input.native="searchUser(userSearch)"
                    clearable
                    item-color="green"
                >
                  <template v-slot:label>
                    Фильтровать по исполнителю
                  </template>
                  <template v-slot:append-item>
                    <div v-intersect="userIntersect"/>
                  </template>
                  <template v-slot:item="{item}">
                    <v-avatar
                        color="#FFFFFF"
                        size="30"
                        class="user-page__avatar mr-3"
                    >
                      <img
                          :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                          alt=""
                      >
                    </v-avatar>
                    <div class="v-list-item__title">{{ item.name }}</div>
                  </template>
                  <template v-slot:append>
                                    <span class="evi-autocomplete__arrow">
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </span>
                  </template>
                </v-autocomplete>
                <v-menu
                    v-model="menuSendDateFilter"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="sendDateFilterFormated"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        label="Дата отправки (доставка)"
                        color="#44D370"
                        class="evi-text-field"
                        clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="sendDateFilter"
                      @input="menuSendDateFilter = false"
                      color="#44D370"
                      first-day-of-week="1"
                  ></v-date-picker>
                </v-menu>
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </es-table>
    <div class="kanban" v-else>
      <div class="deals__actions" v-if="hasRight('deals.manage')">
        <h3 style="color: #FF8577">Воронка продаж</h3>
      </div>
      <v-dialog
          v-model="createDealModal"
          max-width="612px"
      >
        <CreateDeal @updateDeals="getData" :selectedStatus="selectedStatus" @close="createDealModal = false"/>
      </v-dialog>
      <EsFiltration :showByOptions="showByOptions" class="deals" :showBy="30" :itemsLength="tableMeta.total"
                    @updatePages="getDealsPages"
                    sortable :filter="filters"
                    download="deals.export" :importData="importData" @downloadData="downloadData" @uploadData="uploadData" upload="deals.import" :importFormats="importFormats" :exportFormats="exportFormats">
        <template slot="sort-menu">
          <v-list dense class="settings-menu">
            <v-list-item-group>
              <v-list-item>
                <v-list-item-title>
                  <v-autocomplete
                      class="evi-autocomplete mb-5"
                      color="#44D370"
                      v-model="filters['filter[deal_status_id]']"
                      :items="statuses"
                      item-text="name"
                      item-value="id"
                      hide-details
                      clearable
                      item-color="green"
                  >
                    <template v-slot:label>
                      Фильтровать по статусу
                    </template>
                    <template v-slot:append>
                                    <span class="evi-select__arrow">
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </span>
                    </template>
                    <template v-slot:selection="{item}">
                      <div class="v-select__selection v-select__selection--comma"
                           :style="{color: `${item.color}`}">{{ item.name }}
                      </div>
                    </template>
                    <template v-slot:item="{item}">
                      <v-list-item-title :style="{color: `${item.color}`}">
                        {{ item.name }}
                      </v-list-item-title>
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                      class="evi-autocomplete mb-5"
                      color="#44D370"
                      :items="clients"
                      v-model="filters['filter[client_id]']"
                      item-text="name"
                      item-value="id"
                      hide-details
                      :loading="clientsLoading"
                      @focus="loadClients"
                      :search-input.sync="clientSearch"
                      @input.native="searchClient(clientSearch)"
                      clearable
                      item-color="green"
                  >
                    <template v-slot:label>
                      Фильтровать по клиенту
                    </template>
                    <template v-slot:append-item>
                      <div v-intersect="clientIntersect"/>
                    </template>
                    <template v-slot:item="{item}">
                      <v-avatar
                          color="#FFFFFF"
                          size="30"
                          class="user-page__avatar mr-3"
                      >
                        <img
                            :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                            alt=""
                        >
                      </v-avatar>
                      <div class="v-list-item__title">{{ item.name }}</div>
                    </template>
                    <template v-slot:append>
                                    <span class="evi-autocomplete__arrow">
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </span>
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                      class="evi-autocomplete"
                      color="#44D370"
                      :items="users"
                      v-model="filters['filter[executor_id]']"
                      item-text="name"
                      item-value="id"
                      hide-details
                      :loading="userLoading"
                      @focus="loadUsers"
                      :search-input.sync="userSearch"
                      @input.native="searchUser(userSearch)"
                      clearable
                      item-color="green"
                  >
                    <template v-slot:label>
                      Фильтровать по исполнителю
                    </template>
                    <template v-slot:append-item>
                      <div v-intersect="userIntersect"/>
                    </template>
                    <template v-slot:item="{item}">
                      <v-avatar
                          color="#FFFFFF"
                          size="30"
                          class="user-page__avatar mr-3"
                      >
                        <img
                            :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                            alt=""
                        >
                      </v-avatar>
                      <div class="v-list-item__title">{{ item.name }}</div>
                    </template>
                    <template v-slot:append>
                        <span class="evi-autocomplete__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
                    </template>
                  </v-autocomplete>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </template>
      </EsFiltration>
      <div class="ml-6 d-flex flex-wrap" v-if="loading">
        <v-skeleton-loader
            type="image"
            width="300"
            height="100%"
            v-for="i in 10"
            :key="i"
            class="mr-3 mb-3"
        />
      </div>
      <div id="table-vertical-scrollbar">
        <div id="table-scroll"></div>
      </div>
      <kanban-board v-if="!loading" :stages="stages" :blocks="blocks" @update-block="updateBlock" class="pt-10">
        <div v-for="stage in stages" :slot="stage" :key="stage">
          <div class="column-headline" :style="{backgroundColor: getStatus(stage).color}">
            {{ stage }}
            <span class="counter">{{ getCountDealsByStatus(stage) }}</span>
            <v-icon @click="createDealWithStatus(getStatus(stage).id)" class="create pointer" color="#2C3136"
                    v-if="hasRight('deals.manage')">mdi-plus
            </v-icon>
          </div>
          <div @click="createDealWithStatus(getStatus(stage).id)" class="add-deal pointer" v-if="hasRight('deals.manage')">
            <v-icon class="create pointer" color="#808386">mdi-plus
            </v-icon>
            Добавить сделку
          </div>
        </div>
        <div v-for="block in blocks" :slot="block.id" :key="block.id" @click="openDealPage(block.id)">
          <div class="client-info" v-if="block.client">
            {{ block.client.name }}
            <v-avatar
                color="#FFFFFF"
                size="50"
                class="user-page__avatar"
            >
              <img
                  :src="block.client.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                  alt=""
              >
            </v-avatar>
          </div>
          <div class="deal-name">
            <img :src="require('@/assets/img/deals/deal.png')"/>
            {{ block.title }}
          </div>
        </div>
      </kanban-board>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import CreateDeal from "../../components/deals/CreateDeal";
import {hasRight} from '@/utils/access/hasRight';
import {ClientAutocompleteRequestsMixin} from "@/mixins/autocomplete/ClientAutocompleteRequestsMixin";
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";
import {OpenPagesMixin} from "@/mixins/OpenPagesMixin";
import EsFiltration from "../../components/сommon/EsTable/EsFiltration";
import exportData from '@/utils/files/requestExport';
import {RouteFilterPaginationMixin} from "../../mixins/RouteFilterPaginationMixin";

export default {
  name: "Deals",
  components: {EsFiltration, CreateDeal},
  mixins: [ClientAutocompleteRequestsMixin, UserAutocompleteRequestsMixin, OpenPagesMixin, RouteFilterPaginationMixin],
  data: () => ({
    createDealModal: false,
    statusFilter: null,
    selectedStatus: null,
    dealsView: 0,
    stages: [],
    blocks: [],
    menuSendDateFilter: false,
    sendDateFilter: null,
    sendDateFilterFormated: '',
    showByOptions: [
      {
        text: '5',
        counter: 5,
      },
      {
        text: '10',
        counter: 10,
      },
      {
        text: '15',
        counter: 15,
      },
      {
        text: '30',
        counter: 30,
      },
      {
        text: '50',
        counter: 50,
      },
      {
        text: '100',
        counter: 100,
      },
    ],
    headers: [
      {
        text: 'Название (номер)',
        value: 'name',
      },
      {
        text: 'ID сделки',
        value: 'id',
      },
      {
        text: 'Дата создания',
        value: 'created_at',
      },
      {
        text: 'Клиент',
        value: 'client.name',
      },
      {
        text: 'Статус',
        value: 'deal_status.name',
      },
      {
        text: 'Исполнитель',
        value: 'executor',
      },
      // {
      //   text: 'Трек-номер доставки',
      //   value: 'track',
      // },
      {
        text: 'Дата первого контакта',
        value: 'client.first_contact_date',
      },
      {
        text: 'Дата последнего контакта',
        value: 'client.last_contact_date',
      },
      {
        text: 'Время ответа сотрудником на 1-ое сообщение',
        value: 'client.first_message_answer_time',
      },
      {
        text: 'Комментарий',
        value: 'last_comment.comment',
      },
      {
        text: 'Сумма покупки',
        value: 'sum',
      },
      {
        text: 'Ответственное подразделение',
        value: 'subdivision.name',
      },
    ],
    filters: {
      'filter[deal_status_id]': undefined,
      'filter[executor_id]': undefined,
      'filter[client_id]': undefined,
      'send_date_from': undefined,
      'send_date_to': undefined,
    },
    importData: {
      title: 'Импорт сделок',
      template: 'deals'
    },
    importFormats: ['xlsx', 'csv'],
    exportFormats: ['BlueSales'],
  }),
  computed: {
    ...mapState("deals", ["deals",]),
    ...mapState("deal-statuses", ["statuses",]),
    ...mapState("common", ["tableMeta", "loading"]),
    darkTheme() {
      return localStorage.getItem("dark_theme") === "true";
    },
  },
  methods: {
    ...mapActions({
      getDealsPages: 'deals/getDealsPages',
      resetDeals: 'deals/resetDeals',
      getStatuses: 'deal-statuses/getStatuses',
      editDeal: 'deals/editDealWithoutLoading',
      uploadDeals: 'deals/uploadDeals',
    }),
    downloadData(payload) {
      let copyPayload = {
        export: payload.export,
        date_from: payload.date_from,
        date_to: payload.date_to,
        send_date_from: payload.send_date_from,
        send_date_to: payload.send_date_to,
      }
      exportData(this.$store.dispatch, 'deals/downloadDeals', copyPayload, `Сделки`);
    },
    uploadData(payload) {
      this.uploadDeals(payload).then(() => {
        this.getData();
      });
    },
    getStatus(name) {
      return this.statuses.find(i => i.name === name);
    },
    createDealWithStatus (status) {
      this.selectedStatus = status;
      this.createDealModal = true;
    },
    getCountDealsByStatus(name) {
      let i = 0;
      this.blocks.map(s => {
        if (s.status === name) i++;
      });
      return i;
    },
    selectView(view) {
      this.dealsView = view;
      localStorage.setItem(
          "deals_view",
          view.toString()
      );
      this.getData();
    },
    isActiveCard (v) {
      if (this.dealsView === v) {
        return 'active';
      }
    },
    updateBlock(e, status) {
      this.blocks.find(i => i.id === parseInt(e)).status = status;
      let deal = this.deals.find(i => i.id === parseInt(e));
      let payload = {
        name: deal.name,
        executor_id: deal.executor.id,
        id: deal.id,
        deal_status_id: this.statuses.find(i => i.name === status).id,
      };
      this.editDeal(payload);
    },
    hasRight: hasRight,
    getData() {
      this.getDealsPages({
        per_page: this.dealsView ? 15 : 10,
        page: 1,
      }).finally(() => {
        this.setKanban();
      });
    },
    setKanban() {
      //kanban
      this.stages = [];
      this.blocks = [];

      let priority = ["paid", "active", "cancelled"];
      this.statuses.sort((a, b) => priority.indexOf(a.stage) - priority.indexOf(b.stage));
      this.statuses.forEach(i => {
        this.stages.unshift(i.name);
      });
      this.deals.forEach(i => {
        this.blocks.unshift({
          id: i.id,
          status: i.deal_status.name,
          title: i.name,
          client: i.client,
        });
      });
    },
    scrollWatcher () {
      let element = document.getElementById("table-scroll");
      let scrollbar = document.getElementById("table-vertical-scrollbar");
      let table = document.querySelector('.drag-container');

      if (table) {
        element.style.width = table.scrollWidth + 'px';
        scrollbar.style.width = table.offsetWidth + 12 +  'px';

        table.addEventListener('scroll', function(){
          scrollbar.scrollLeft = table.scrollLeft;
        });
        scrollbar.addEventListener('scroll', function(){
          table.scrollLeft = scrollbar.scrollLeft;
        });
      }
    },
  },
  mounted() {
    this.getStatuses();

    this.routeFiltersInit(this.filters);

    this.dealsView = parseInt(localStorage.getItem("deals_view"));
    setTimeout(() => {
      this.scrollWatcher();
    }, 1000);
  },
  beforeDestroy() {
    this.resetDeals();
  },
  watch: {
    'deals': {
      handler: function () {
        this.setKanban();
        this.scrollWatcher();
      },
      deep: true,
      immediate: true
    },
    sendDateFilter (val) {
      if (val) {
        this.filters['send_date_to'] = val;
        this.filters['send_date_from'] = val;
        this.sendDateFilterFormated = this.$moment(val).format('DD.MM.YYYY');
      } else {
        this.filters['send_date_to'] = null;
        this.filters['send_date_from'] = null;
      }
    },
    'filters.send_date_to' (val) {
      if (val) {
        this.sendDateFilter = val;
      } else {
        this.sendDateFilter = '';
      }
    },
    sendDateFilterFormated (val) {
      if (!val) {
        this.sendDateFilter = '';
      }
    },
    filters: {
      handler (val) {
        this.filterWatcher(val);
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.deals__actions {
  margin-left: 23px;
  display: flex;

  h3 {
    font-weight: 700;
    font-size: 20px;
    margin-left: 0;
    margin-right: 40px;
  }
}

.kanban {
  background: #fff;
  box-shadow: 10px -2px 54px rgb(0 0 0 / 6%);
  border-radius: 10px;
  padding: 60px 25px;
  margin-bottom: 25px;
}

#table-scroll{
  width: 50px;
  height: 12px;
  background: transparent;
}

#table-vertical-scrollbar {
  width: 50px;
  height: 10px;
  position: absolute;
  margin-left: 14px;
  overflow-x: auto;
  border-radius: 50%;
  background: transparent;
}
</style>
